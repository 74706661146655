/* global moment */
/* exported reformatEvents */

function reformatEvents(target = '.msl_eventlist', defaultimage = 'https://code.derbyunion.co.uk/usdev/images/us-scribble.svg') {
  function getTimefromMSL(time) {
    if (time === 'noon') {
      return [12, 0, 0]
    } if (time === 'midnight') {
      return [0, 0, 0]
    }
    const meridiem = time.substring(time.length - 2) === 'pm' ? 12 : 0
    const arr = time.split(':')
    let hh = parseInt(arr[0].replace(/\D/g, ''), 10)
    hh = hh !== 12 ? hh + meridiem : hh
    const mm = arr[1] ? arr[1].replace(/\D/g, '') : 0
    return [hh, mm, 0]
  }

  // Polyfill Number.isNaN for ie
  Number.isNaN = Number.isNaN || function (value) { return value !== value }

  function getMonthFromString(month) {
    const d = Date.parse(`${month} 1, 2017`)
    if (!Number.isNaN(d)) {
      return new Date(d).getMonth()
    }
    return null
  }

  function parseMSLDate(datestring) {
    const today = new Date()
    const mslDates = []
    const arr = datestring.split(' ')

    // Get start date
    const day = arr[0].replace(/\D/g, '')
    const month = getMonthFromString(arr[1])
    const start = getTimefromMSL(arr[2])
    const year = month < today.getMonth() ? today.getFullYear() + 1 : today.getFullYear() // We're guessing the year here
    // const year = today.getFullYear() // We're guessing the year here
    mslDates.push(new Date(year, month, day, start[0], start[1], start[2]))

    // Get end date
    if (arr.length < 6) { // This is a single-day event
      const end = getTimefromMSL(arr[4])
      mslDates.push(new Date(year, month, day, end[0], end[1], end[2]))
    } else { // This is a multi-day event
      const endDay = arr[4].replace(/\D/g, '')
      const endMonth = getMonthFromString(arr[5])
      const end = getTimefromMSL(arr[6])
      // const endYear = endMonth < today.getMonth() ? today.getFullYear() + 1 : today.getFullYear() // We're guessing the year here
      const endYear = today.getFullYear() // We're guessing the year here
      mslDates.push(new Date(endYear, endMonth, endDay, end[0], end[1], end[2]))
    }

    if (moment(mslDates[1]).isBefore(moment(mslDates[0]))) { mslDates[1] = moment(mslDates[1]).add(1, 'years') }
    // console.log(`Starts: ${mslDates[0]} and ends ${mslDates[1]}`)
    // console.log(mslDates)

    return mslDates
  }

  function timeAgo(time) {
    return moment().to(time)
  }

  function formatEvents(eventlist) {
    let html = ''
    eventlist.forEach(mslEvent => {
      // console.log(mslEvent)
      if (!mslEvent.attributes.includes('hidden')) {
        html += `
              <div class="eventlist-item  swiper-slide  event-card ${mslEvent.attributes.includes('cancelled') ? ` event-cancelled` : ''} ${mslEvent.brand != null ? ` msl-brand-${mslEvent.brand}` : ''}" data-orgid="${mslEvent.org[0]}">
                  <a class="event-card__link" href="${mslEvent.link}">
                      <div class="event-card__image" style="background-image: url('${mslEvent.image}')">
                          <div></div>
                      </div>
      
                      <div class="event-card__org">${mslEvent.org.toString().split(/,(.+)/)[1]} </div>
      
                      <div class="event-card__content">
                          <div class="event-card__title">${mslEvent.title}</div>
                          <div class="event-card__lead">${mslEvent.lead}</div>
                          <div class="event-card__meta">
                              <p>
                                  <i class="far  fa-fw  fa-calendar-times" aria-hidden="true"></i> ${moment(mslEvent.date[0]).diff(moment(), 'hours') < 0 ? 'started' : 'happening'} 
                                      <time datetime="${mslEvent.date[0]}" title="${mslEvent.date[0]}">${timeAgo(mslEvent.date[0])}</time>
                              </p>
                              ${mslEvent.tickets ? '<p><i class="fas  fa-fw  fa-ticket-alt" aria-hidden="true"></i> buy your ticket online</p>' : ''}
      
                          </div>
                      </div>
                  </a>
              </div>

`
      }
    })
    return html
  }

  const mslevents = [].slice.call(document.querySelectorAll(`${target} .event_item`))
  const ourevents = mslevents.map(event => {
    const title = event.querySelector('.msl_event_name').innerText
    const link = event.querySelector('dt a').href
    const org = [event.dataset.mslOrganisationId, event.querySelector('.msl_event_organisation').innerText]

    const image = event.querySelector('.msl_event_image img') ? event.querySelector('.msl_event_image img').src.split('?') : [defaultimage]
    const lead = event.querySelector('.msl_event_description') ? event.querySelector('.msl_event_description').innerText : null
    const loc = event.querySelector('.msl_event_location') ? event.querySelector('.msl_event_location').innerText : null
    const brand = event.querySelector('.msl_event_brand') ? event.querySelector('.msl_event_brand').innerText : null

    const attributes = Array.from(event.querySelectorAll('.msl_event_types a')).map(attr => attr.href.match(/[^\/]+(?=\/$|$)/)[0])

    const date = parseMSLDate(event.querySelector('.msl_event_time').innerText)

    const mslimageoptions = '?thumbnail_width=460&amp;thumbnail_height=0&amp;resize_type=ResizeWidth'
    const isticketed = !!event.querySelector('.msl_event_tickets')

    return {
      title,
      link,
      org,
      image: image[0] + mslimageoptions,
      lead,
      loc,
      brand,
      attributes,
      date,
      tickets: isticketed
    }
  })

  document.querySelector(target).innerHTML = formatEvents(ourevents)
}
